const mutations = {
  setBoards(state, boards) {
    boards.forEach((board) => {
      board.columns = board.columns.sort((c1, c2) => c1.indexNumber - c2.indexNumber);
    });
    state.boards = boards;
  },
  addBoard(state, board) {
    state.boards.push(board);
  },
  deleteBoard(state, removedBoard) {
    state.boards = state.boards.filter((board) => board.id !== removedBoard.id);
  },
};

export default mutations;

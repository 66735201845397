var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", { class: _vm.headerThemeClasses }, [_c("header-layout")], 1),
    _vm._v(" "),
    _c(
      "div",
      { class: ["container-wrapper", _vm.containerWrapperThemeClasses] },
      [
        _c(
          "div",
          { staticClass: "notification-container" },
          _vm._l(_vm.notificationList, function(notification) {
            return _c("notification-item", {
              key: notification.id,
              staticClass: "notification-item",
              attrs: { notification: notification }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c(
            "article",
            { ref: "article", staticClass: "container__article" },
            [
              _c(
                "transition",
                { attrs: { name: "expand" } },
                [
                  _vm.loggedIn && _vm.sidebarToggled.sidebarToggled
                    ? _c("v-side-bar", {
                        class: [
                          "sidebar-container",
                          {
                            "sidebar-toggled": _vm.sidebarToggled.sidebarToggled
                          }
                        ],
                        attrs: { "footer-rect": _vm.footerRect }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.loggedIn
                ? _c("router-view", {
                    key: _vm.$route.path,
                    staticClass: "content"
                  })
                : _c("sign-in-layout")
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "footer",
      { ref: "footer", class: _vm.footerThemeClasses },
      [_c("footer-layout")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
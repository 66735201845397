import axios from 'axios';

export default {
  addNotification({ commit }, notification) {
    commit('addNotification', notification);
  },
  removeNotification({ commit }, notification) {
    commit('removeNotification', notification);
  },
  toggleSidebar({ commit }, { sidebarToggled, toggler = 'window' }) {
    commit('toggleSidebar', { sidebarToggled, toggler });
  },
  setBoardsCardsSorting({ commit }, boardId) {
    commit('setBoardsCardsSorting', boardId);
  },
  saveBoardsCardsSortingMethod({ commit }, { boardId, columnSortingMethodName }) {
    commit('saveBoardsCardsSortingMethod', { boardId, columnSortingMethodName });
  },
  saveBoardsSortingMethod({ commit }, columnSortingMethodName) {
    commit('saveBoardsSortingMethod', columnSortingMethodName);
  },
  switchTheme({ commit }, { isDarkMode }) {
    commit('switchTheme', { isDarkMode });

    return axios.post(
      '/api/switch_theme',
      {
        isDarkMode,
      },
      {
        params: {
          retry: {
            count: 3,
            delay: 200,
          },
        },
      },
    );
  },
  openProfileDialog({ commit }) {
    commit('switchProfileDialog', true);
  },
  closeProfileDialog({ commit }) {
    commit('switchProfileDialog', false);
  },
};

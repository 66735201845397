import axios from 'axios';

export default {
  likeAction(_, card) {
    return axios.post(`/api/cards/${card.id}/rating`, {});
  },
  dislikeAction(_, card) {
    return axios.delete(`/api/cards/${card.id}/rating`);
  },
};

import Vue from 'vue';
import VueI18n from 'vue-i18n';

const content = require('./content.yml');

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  messages: content,
});

export default i18n;

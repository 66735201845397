export const initialState = () => ({
  id: -1,
  name: '',
  description: '',
  createDate: '',
  shareLink: '',
  owner: {},
  columns: [],
  columnList: [],
  cards: [],
  comments: [],
  members: [],
  wereCommentsUploaded: false,
  isCreatingCard: false,
  settings: {
    spy: false,
  },
});

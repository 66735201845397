var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.footerThemeClasses }, [
    _c("div", { class: _vm.rowThemeClasses }, [
      _c("div", [
        _c(
          "a",
          {
            class: _vm.buttonThemeClasses.concat(["support"]),
            attrs: {
              href: "link_stub",
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [_vm._v(_vm._s(_vm.$t("footer.buttons.support")))]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            class: _vm.buttonThemeClasses,
            attrs: {
              href: "link_stub",
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [_vm._v(_vm._s(_vm.$t("footer.buttons.feedback")))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-wrapper" },
    [
      _vm.isProfileDialogOpen
        ? _c("profile-dialog", {
            on: { save: _vm.closeProfileDialog, close: _vm.closeProfileDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.loggedIn
          ? _c(
              "div",
              { staticClass: "col logo-place" },
              [
                _c(
                  "router-link",
                  { class: _vm.logoThemeClasses, attrs: { to: "/" } },
                  [
                    _c("span", { class: _vm.prefixThemeClasses }, [
                      _vm._v("My")
                    ]),
                    _vm._v("Retro\n      ")
                  ]
                ),
                _vm._v(" "),
                _c("v-burger-menu")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "spacer" }),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm._l(_vm.languages, function(locale) {
                return _c(
                  "v-flat-button",
                  {
                    key: locale,
                    staticClass: "button",
                    attrs: {
                      activated: _vm.$i18n.locale === locale,
                      "data-testid": "lang-switcher-to-" + locale
                    },
                    on: {
                      click: function($event) {
                        return _vm.switchLanguage(locale)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(locale.toUpperCase()) +
                        "\n        "
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "div",
                    { staticClass: "col logout" },
                    [
                      _c(
                        "v-flat-button",
                        {
                          staticClass: "button",
                          attrs: { activated: true },
                          on: { click: _vm.logout }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("logout")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
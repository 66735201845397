import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    actionItems: [],
    mentions: [],
    unreadActionItemsCount: 0,
    unreadMentionsCount: 0,
    actionItemsFilterName: '',
    sortingName: '',
  },
  mutations,
  actions,
  getters,
};

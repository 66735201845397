import Vue from 'vue';
import Vuex from 'vuex';

import board from './modules/board';
import app from './modules/app';
import dashboard from './modules/dashboard';
import user from './modules/user';
import mentions from './modules/mentions';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    board,
    app,
    dashboard,
    user,
    mentions,
  },
  plugins: [],
});

export default store;

import Vue from 'vue';
import { initialState } from './initialState';

const commonFunctions = {
  addComment(state, comment) {
    Vue.set(state.comments, comment.id, comment);
    if (state.cards[comment.cardId].comments.includes(comment.id)) return;
    state.cards[comment.cardId].comments.push(comment.id);
    state.cards[comment.cardId].commentsCount += 1;
  },
  addCard(state, card) {
    Vue.set(state.cards, card.id, {
      ...card,
      comments: [],
    });

    state.columns[card.columnId].cards.push(card.id);
  },
  updateCard(state, card) {
    state.cards[card.id].hide = card.hide;
    state.cards[card.id].content = card.content;
    state.cards[card.id].user = card.user;
  },
};

const mutations = {
  addCard(state, card) {
    if (state.isCreatingCard) {
      card.isEditing = true;
      state.isCreatingCard = false;
    }
    commonFunctions.addCard(state, card);
  },
  updateCard(state, card) {
    commonFunctions.updateCard(state, card);
  },
  updateBatchCards(state, cards) {
    cards.forEach((card) => commonFunctions.updateCard(state, card));
  },
  deleteCard(state, card) {
    card.comments.forEach((comment) => {
      Vue.delete(state.comments, state.comments.indexOf(comment.id));
    });

    Vue.delete(state.cards, state.cards.indexOf(card.id));
    Vue.delete(state.columns[card.columnId].cards, state.columns[card.columnId].cards.indexOf(card.id));
  },
  addComment(state, comment) {
    commonFunctions.addComment(state, comment);
  },
  addCommentsList(state, commentsList) {
    commentsList.forEach((comment) => commonFunctions.addComment(state, comment));
    state.wereCommentsUploaded = true;
  },
  updateComment(state, comment) {
    state.comments[comment.id].content = comment.content;
  },
  deleteComment(state, comment) {
    Vue.delete(state.comments, state.comments.indexOf(comment.id));
    Vue.delete(state.cards[comment.cardId].comments, state.cards[comment.cardId].comments.indexOf(comment.id));
    state.cards[comment.cardId].commentsCount -= 1;
  },
  updateBoard(state, board) {
    state.name = board.name;
    state.description = board.description;
    state.shareLink = board.shareLink;
    state.createDate = board.createDate;
    state.owner = board.owner;
  },
  updateColumn(state, column) {
    state.columns[column.id].color = column.color;
  },
  updateRating(state, card) {
    const { id, likesCount, userLike } = card;
    const userId = this.state.user.id;
    const oldCard = state.cards[id];

    oldCard.likesCount = likesCount;
    if (userId === card.currentUserId) {
      oldCard.userLike = userLike;
    }
  },
  setMembers(state, members) {
    state.members = members;
  },
  setBoard(state, board) {
    state.id = board.id;
    state.name = board.name;
    state.description = board.description;
    state.owner = board.owner;
    state.shareLink = board.shareLink;
    state.createDate = board.createDate;
    state.settings = board.settings;

    board.columns.forEach((column) => {
      Vue.set(state.columns, column.id, {
        id: column.id,
        name: column.name,
        color: column.color,
        boardId: column.boardId,
        indexNumber: column.indexNumber,
        isActionItems: column.isActionItems,
        cards: [],
      });
      state.columnList.push(column.id);
      column.cards.forEach((card) => {
        commonFunctions.addCard(state, card);
      });
    });
  },
  clearBoard(state) {
    Object.assign(state, initialState());
  },
  setCreatingCardStatus(state, status) {
    state.isCreatingCard = status;
  },
  moveCard(state, card) {
    const oldCard = state.cards[card.id];

    state.columns[oldCard.columnId].cards = state.columns[oldCard.columnId].cards
      .filter((cardId) => cardId !== card.id);
    state.columns[card.columnId].cards.push(card.id);
    state.cards[card.id].columnId = card.columnId;
  },
  toggleSpy(state) {
    state.settings.spy = !state.settings.spy;
  },
};

export default mutations;

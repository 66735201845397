import Vue from 'vue';
import Router from 'vue-router';
import './axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeart,
  faTrashAlt,
  faSearch,
  faPlus,
  faCaretDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import linkify from 'vue-linkify';
import { faCustomComment, faLike } from '~/modules/customFontAwesomeIcon';
import Layout from '~/components/layout/Layout';
import router from './routes';
import store from './store';
import i18n from './locales';
import notification from './NotificationPlugin';
import './style/application.sass';

import VueMaterial from 'vue-material'
//import 'vue-material/dist/vue-material.min.css'
import './style/vue-material-fixed.min.css'
import 'vue-material/dist/theme/default.css'

library.add(
  faHeart,
  faTrashAlt,
  faSearch,
  faPlus,
  faCaretDown,
  faTimes,
  faCustomComment,
  faLike,
);

const isProd = process.env.NODE_ENV === 'production';

Vue.use(Router);
Vue.use(notification);
Vue.directive('linkified', linkify);

Vue.config.devtools = !isProd;
Vue.config.silent = !isProd;
Vue.config.productionTip = !isProd;

Vue.use(VueMaterial);
// Patch for fix MdSelect problem
Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
  methods: {
    isInvalidValue: function isInvalidValue () {
      return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
    }
  }
}))

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  components: { Layout },
  router,
  store,
  i18n,
  notification,
});

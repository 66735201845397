import axios from 'axios';

const actions = {
  getColumnVariants() {
    return axios.get('/api/templates');
  },
  addBoard({ commit }, dto) {
    return axios.post('/api/boards', dto)
      .then((response) => {
        commit('addBoard', response);
        commit('user/setBoardsCount', 1, { root: true });

        return response;
      });
  },
  deleteBoard({ commit }, board) {
    return axios.delete(`/api/boards/${board.id}`)
      .then(() => {
        commit('deleteBoard', board);
        commit('user/setBoardsCount', -1, { root: true });
      });
  },
  getBoards({ commit }) {
    return axios.get('/api/boards')
      .then((response) => {
        commit('setBoards', response);

        return response;
      });
  },
};

export default actions;

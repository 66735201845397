<template>
  <div class="burger-container">
    <input
      ref="checkbox"
      type="checkbox"
      class="hamburger-checkbox"
      @click="onClick"
      data-qa="burger-menu"
    >
    <span :class="['stick', ...themeClasses]" />
    <span :class="['stick', ...themeClasses]" />
    <span :class="['stick', ...themeClasses]" />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'VBurgerMenu',
    data() {
      return {
        isMenuToggled: false,
      };
    },
    created() {
      window.addEventListener('click', this.closeSidebar);
      window.addEventListener('dragstart', this.closeSidebar);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeSidebar);
      window.addEventListener('dragstart', this.closeSidebar);
    },
    computed: {
      ...mapState('app', ['isDarkMode']),
      themeClasses() {
        return this.isDarkMode ? [] : ['light'];
      },
    },
    methods: {
      ...mapActions('app', [
        'toggleSidebar',
      ]),
      onClick() {
        this.isMenuToggled = !this.isMenuToggled;
        this.toggleSidebar({ sidebarToggled: this.isMenuToggled, toggler: 'burgerMenu' });
      },
      closeSidebar({ target }) {
        const sidebarContainer = document.querySelector('.sidebar-container');
        const menusContainer = document.querySelector('.menus-container');
        const menuHeading = document.querySelector('.heading');
        const restrictedZone = !sidebarContainer ||
          target === sidebarContainer ||
          target === menusContainer ||
          target === menuHeading;

        if (restrictedZone) {
          return;
        }

        if (!this.$el.contains(target) && this.isMenuToggled) {
          this.isMenuToggled = !this.isMenuToggled;
          this.toggleSidebar({ sidebarToggled: this.isMenuToggled, toggler: 'window' });
          this.$refs.checkbox.checked = this.isMenuToggled;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "~/style/styleguide.sass"

  .burger-container
    position: relative
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    margin-left: base-unit(24.3)

    &:hover
      .stick
        background: $iron-color

    .hamburger-checkbox
      position: absolute
      width: 100%
      height: 100%
      opacity: 0
      margin: 0
      top: 0
      left: 0
      z-index: $header-z-index

      &:hover
        cursor: pointer

      &:checked ~ .stick
        transform: translateY(#{base-unit(-9)}) rotate(-45deg)
        width: base-unit(30)
      &:checked ~ .stick:nth-last-child(3)
        opacity: 0
        transform: rotate(0deg) scale(0.2, 0.2)
      &:checked ~ .stick:nth-last-child(2)
        transform: rotate(45deg)
      & ~ .stick:nth-last-child(1)
        margin-bottom: 0

    .stick
      display: block
      width: base-unit(27)
      height: base-unit(4)
      margin-bottom: base-unit(5)
      position: relative
      transform-origin: center
      background: $storm-gray
      +burger-transition

      &.light
        background: $ebony-clay-color

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.confirmDialogThemeClasses }, [
    _c("div", { staticClass: "content" }, [
      _c("span", [_vm._v(_vm._s(_vm.text))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          staticClass: "actions-button",
          on: {
            click: function($event) {
              return _vm.$emit("cancel")
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.negativeLabel) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "actions-button",
          on: {
            click: function($event) {
              return _vm.$emit("confirm")
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.positiveLabel) + "\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="header-wrapper">
    <profile-dialog
        v-if="isProfileDialogOpen"
        @save="closeProfileDialog"
        @close="closeProfileDialog"
    />
    <div class="content">
      <div class="col logo-place" v-if="loggedIn">
        <router-link to="/" :class="logoThemeClasses">
          <span :class="prefixThemeClasses">My</span>Retro
        </router-link>
        <v-burger-menu/>
      </div>
      <div class="spacer"></div>
      <div class="col">
        <div class="row">
          <v-flat-button
            v-for="locale in languages"
            :key="locale"
            :activated="$i18n.locale === locale"
            class="button"
            :data-testid="`lang-switcher-to-${locale}`"
            @click="switchLanguage(locale)"
          >
            {{ locale.toUpperCase() }}
          </v-flat-button>
          <div class="col logout" v-if="loggedIn">
            <v-flat-button
              :activated="true"
              @click="logout"
              class="button">
              {{ $t('logout') }}
            </v-flat-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import VFlatButton from '../VFlatButton.vue';
  import VBurgerMenu from '../VBurgerMenu.vue';

  export default {
    name: 'HeaderLayout',
    components: {
      VFlatButton,
      VBurgerMenu,
      ProfileDialog: () => import('~/components/VProfileDialog.vue'),
    },
    computed: {
      ...mapState('app', [
        'languages',
        'isDarkMode',
        'isProfileDialogOpen'
      ]),
      ...mapState('user', ['loggedIn']),
      logoThemeClasses() {
        return ['logo', this.isDarkMode || 'logo-light'];
      },
      prefixThemeClasses() {
        return ['prefix', this.isDarkMode || 'prefix-light'];
      },
    },
    methods: {
      ...mapActions('app', [
        'closeProfileDialog',
      ]),
      ...mapActions('user', [
        'logout',
      ]),
      switchLanguage(lang) {
        this.$i18n.locale = lang;
        localStorage.setItem('locale', lang);
      },
    },
  };
</script>

<style scoped lang="sass">
  @import "~/style/styleguide.sass"

  .header-wrapper
    height: 100%

  a
    color: inherit
    text-decoration: none

  .spacer
    flex-grow: 1

  .col
    display: flex
    align-items: center
    height: 100%

  .content
    justify-content: space-between
    align-items: center
    display: flex
    flex-wrap: wrap
    //height: base-unit(64)
    padding: 0 base-unit(30)
    +large-devices
      padding: 0 base-unit(50)
    +high-resolution-devices
      width: $max-content-width
      margin: 0 auto

    .button
      +main-font()
      font-weight: 500
      font-size: $base-font-size
      margin-right: base-unit(21)
      padding: base-unit(10) 0

    .logout
      display: inline-block
      text-transform: lowercase

      .button
        margin: 0 0 0 base-unit(43)
        width: base-unit(50)

    .logo-place
      justify-content: space-between
      width: base-unit(175)
      padding-right: base-unit(10)

      .logo
        display: flex
        height: 100%
        color: $fg-main
        font-size: base-unit(36)
        line-height: base-unit(59)
        +header-font()

        &-light
          color: $ebony-clay-color

        .prefix
          color: $energy-yellow-color
          &-light
            color: $lightning-yellow-color

</style>

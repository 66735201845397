<template>
  <div class="col main">
    <div class="row logo">
      <span class="yellow">My</span>Retro
    </div>
    <login-dialog
        v-if="isLoginDialogOpen"
        @save="closeLoginDialog"
        @close="closeLoginDialog"
    />
    <div class="row subheader">
      <span v-html="$t('signin.textFirstPart')"/>
      <strong v-html="$t('signin.textBrightPart')"/>
      <span v-html="$t('signin.textLastPart')"/>
    </div>
    <div class="row">
      <a class="signin" @click="openLoginDialog" v-t="'signin.button'" rel="noopener"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SignInLayout',
    components: {
      LoginDialog: () => import('~/components/VLoginDialog.vue'),
    },
    data() {
      return {
        isLoginDialogOpen: false
      };
    },
    beforeMount: function () {
      const [ , errorMsg ] = window.location.toString().split('?error=');

      if (errorMsg) {
        this.$notification.notifyError(
            new Error(decodeURIComponent(errorMsg))
        );
        this.respondToRouteChanges = false;
        this.$router.replace({})
            .finally(() => {
              this.respondToRouteChanges = true;
            });
      }
    },
    methods: {
      openLoginDialog() {
        this.isLoginDialogOpen = true
      },
      closeLoginDialog() {
        this.isLoginDialogOpen = false
      },
    },
  };
</script>

<style scoped lang="sass">
  @import "~/style/styleguide.sass"

  .row
    display: flex

    &.logo
      font-size: base-unit(110)
      +header-font()
      margin-top: base-unit(6)

      +medium-devices
        font-size: base-unit(130)
        height: base-unit(194)

      +small-devices
        font-size: base-unit(100)

      .yellow
        color: $energy-yellow-color

    &.header
      font-size: base-unit(75)
      font-weight: normal
      text-transform: uppercase
      color: $card-white
      max-width: base-unit(644)
      margin-top: base-unit(40)
      letter-spacing: 0.1em

      +small-devices
        font-size: base-unit(40)

    &.subheader
      font-size: base-unit(40)
      font-weight: normal
      color: rgba(255, 255, 255, 0.3)
      max-width: base-unit(1288)
      margin-top: base-unit(14)
      display: block

      strong
        font-weight: normal
        color: white

      +medium-devices
        font-size: base-unit(50)
        line-height: base-unit(70)

      +small-devices
        font-size: base-unit(30)

    .signin
      cursor: pointer
      color: $energy-yellow-color
      font-size: base-unit(40)
      font-weight: normal
      text-decoration: none
      margin-top: base-unit(40)
      margin-bottom: base-unit(17)

      +medium-devices
        font-size: base-unit(50)

      +small-devices
        font-size: base-unit(30)
        margin-top: base-unit(20)

  .col
    display: flex
    flex-direction: column

    &.main
      +main-font
      justify-content: left
      width: 100%


</style>
import axios from 'axios';
import sharedActions from '../sharedActions';

function increaseCount({
  mentions,
  getters,
  gettersKey,
  mutationName,
  dispatch,
  commit,
  isActionItems,
}) {
  const payloadsToReadCards = [];
  const newMentions = mentions.map((mention) => {
    const currentExistingIndex = getters[gettersKey]
      .findIndex((item) => mention.board.shareLink === item.board.shareLink);

    if (currentExistingIndex === -1) {
      return {
        ...mention,
        isCollapsed: true,
      };
    }
    if (!getters[gettersKey][currentExistingIndex].isCollapsed) {
      payloadsToReadCards.push({
        mentions: mention.mentions,
        board: getters[gettersKey][currentExistingIndex].board,
        isCollapsed: getters[gettersKey][currentExistingIndex].isCollapsed,
        isActionItems,
      });
    }

    return {
      ...mention,
      isCollapsed: getters[gettersKey][currentExistingIndex].isCollapsed,
    };
  });

  commit(mutationName, newMentions);
  payloadsToReadCards.forEach((payload) => dispatch('mentions/readCards', payload, { root: true }));
}

export default {
  like({ commit }, {
    card, mentionIndex, mentionObjectIndex, isActionItem,
  }) {
    return sharedActions.likeAction(null, card).then((cardRes) => {
      commit('updateCard', {
        card: cardRes, mentionIndex, mentionObjectIndex, isActionItem,
      });
    });
  },
  dislike({ commit }, {
    card, mentionIndex, mentionObjectIndex, isActionItem,
  }) {
    return sharedActions.dislikeAction(null, card).then((cardRes) => {
      commit('updateCard', {
        card: cardRes, mentionIndex, mentionObjectIndex, isActionItem,
      });
    });
  },
  setActionItems({ commit }, actionItems) {
    commit('setActionItems', actionItems);
  },
  setMentions({ commit }, mentions) {
    commit('setMentions', mentions);
  },
  setUnreadActionItemsCount({ commit }) {
    return axios.get('/api/unread_action_items').then((unreadCount) => {
      commit('setUnreadActionItemsCount', unreadCount);
    });
  },
  setUnreadMentionsCount({ commit }) {
    return axios.get('/api/unread_mentions').then((unreadCount) => {
      commit('setUnreadMentionsCount', unreadCount);
    });
  },
  decreaseUnreadActionItemsCount({ commit }, count) {
    commit('decreaseUnreadActionItemsCount', count);
  },
  increaseUnreadActionItemsCount({ commit, getters, dispatch }, payload) {
    increaseCount({
      mentions: payload.mentions,
      getters,
      dispatch,
      commit,
      gettersKey: 'actionItems',
      mutationName: 'increaseUnreadActionItemsCount',
      isActionItems: true,
    });
  },
  decreaseUnreadMentionsCount({ commit }, count) {
    commit('decreaseUnreadMentionsCount', count);
  },
  increaseUnreadMentionsCount({ commit, getters, dispatch }, payload) {
    increaseCount({
      mentions: payload.mentions,
      getters,
      dispatch,
      commit,
      gettersKey: 'mentions',
      mutationName: 'increaseUnreadMentionsCount',
      isActionItems: false,
    });
  },
  getActions() {
    return axios.get('/api/action_items');
  },
  getMentions() {
    return axios.get('/api/mentions');
  },
  mentionUser({ commit }, mentionPayload) {
    commit('mentionUser', mentionPayload);
  },
  completeActionItem({ commit }, { actionItemId: anchorId, completed: isChecked }) {
    return axios.post('/api/complete_action_item',
      { actionItemId: anchorId, completed: isChecked }).then(() => {
      commit('completeActionItem', { actionItemId: anchorId, completed: isChecked });
    });
  },
  readCards({ commit }, {
    mentions, isActionItems, board, isCollapsed,
  }) {
    return axios.post('/api/read_user_mention', { mentions, isActionItems }).then((mentionItems) => {
      const mappedMentions = mentionItems.map((mention) => ({ ...mention, isCollapsed }));
      const unread = mentions.filter(({ isRead }) => !isRead);

      if (isActionItems) {
        commit('decreaseUnreadActionItemsCount', unread.length);
        commit('setActionItems', {
          board,
          actionItems: mappedMentions,
        });
      } else {
        commit('decreaseUnreadMentionsCount', unread.length);
        commit('setMentions', {
          board,
          mentions: mappedMentions,
        });
      }
    });
  },
  saveActionItemsFilter({ commit }, filterName) {
    commit('saveActionItemsFilter', filterName);
  },
  saveMentionsSorting({ commit }, sortingName) {
    commit('saveMentionsSorting', sortingName);
  },
};

import { set } from 'vue';

export default {
  updateCard(state, {
    mentionIndex, card, mentionObjectIndex, isActionItem,
  }) {
    const mention = state[isActionItem ? 'actionItems' : 'mentions'][mentionObjectIndex].mentions[mentionIndex];
    const [oldCard] = mention.cards;
    const { likesCount, userLike } = card;

    oldCard.likesCount = likesCount;
    oldCard.userLike = userLike;
  },
  completeActionItem(state, { actionItemId, completed }) {
    state.actionItems = state.actionItems.map((actionItem) => {
      const mention = actionItem.mentions.find((_mention) => _mention.cards[0].id === actionItemId);

      if (!mention) {
        return actionItem;
      }

      const indexOfMention = actionItem.mentions.indexOf(mention);

      mention.completed = completed;

      actionItem.mentions[indexOfMention] = mention;

      return actionItem;
    });
  },
  setActionItems(state, { setAll = false, board = {}, actionItems }) {
    if (setAll || Object.keys(board).length === 0) {
      state.actionItems = actionItems;

      return;
    }

    const boardActionItemsIndex = state.actionItems.indexOf(state.actionItems.find(
      (actionItem) => actionItem.board.id === board.id,
    ));

    if (boardActionItemsIndex === -1) {
      return;
    }

    set(state.actionItems, boardActionItemsIndex, actionItems[boardActionItemsIndex]);
  },
  setMentions(state, { setAll = false, board = {}, mentions }) {
    if (setAll || Object.keys(board).length === 0) {
      state.mentions = mentions;

      return;
    }

    const boardMentionsIndex = state.mentions.indexOf(
      state.mentions.find(
        (mention) => mention.board.id === board.id,
      ),
    );

    if (boardMentionsIndex === -1) {
      return;
    }

    set(state.mentions, boardMentionsIndex, mentions[boardMentionsIndex]);
  },
  setUnreadActionItemsCount(state, count) {
    state.unreadActionItemsCount = count;
  },
  setUnreadMentionsCount(state, count) {
    state.unreadMentionsCount = count;
  },
  decreaseUnreadActionItemsCount(state, count) {
    if (state.unreadActionItemsCount - count < 0) {
      state.unreadActionItemsCount = 0;

      return;
    }
    state.unreadActionItemsCount -= count;
  },
  increaseUnreadActionItemsCount(state, newActionItems) {
    state.unreadActionItemsCount += 1;
    state.actionItems = newActionItems;
  },
  decreaseUnreadMentionsCount(state, count) {
    if (state.unreadMentionsCount - count < 0) {
      state.unreadMentionsCount = 0;

      return;
    }
    state.unreadMentionsCount -= count;
  },
  increaseUnreadMentionsCount(state, newMentions) {
    state.unreadMentionsCount += 1;
    state.mentions = newMentions;
  },
  saveActionItemsFilter(state, filterName) {
    state.actionItemsFilterName = filterName;
  },
  saveMentionsSorting(state, sortingName) {
    state.sortingName = sortingName;
  },
};

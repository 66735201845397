/* eslint-disable */
export const faCustomComment = {
  prefix: 'fas',
  iconName: 'custom-comment',
  icon: [17, 15, [], null, 'M0.00019152 14.9832C1.95252 14.9832 3.86282 15.0028 5.75575 14.9997H5.76894C7.04306 14.9997 8.30004 14.9851 9.57217 14.9509C10.5253 14.925 11.5226 14.627 12.4072 14.2449C15.1617 13.0757 16.6751 10.7689 16.8069 8.39949C16.8372 8.19198 16.8577 7.98382 16.8676 7.7744C17.0666 3.64231 13.2482 7.22555e-06 8.76802 7.22555e-06C6.83341 -0.00252366 4.96208 0.659891 3.49151 1.86699C2.02163 3.07415 1.05068 4.74627 0.753364 6.58103C0.456753 8.41579 0.853554 10.2923 1.87324 11.8702C1.8594 11.991 1.82249 12.1081 1.76317 12.215C1.22003 13.1236 0.629435 14.0049 0 14.983L0.00019152 14.9832ZM12.2048 6.56095C12.597 6.56221 12.949 6.78999 13.0986 7.13732C13.2483 7.48466 13.1652 7.88452 12.8884 8.15024C12.6115 8.4166 12.1956 8.49695 11.8331 8.35397C11.4712 8.21098 11.2333 7.87312 11.2313 7.49731C11.2306 7.24931 11.3328 7.01141 11.516 6.83679C11.6986 6.66155 11.9471 6.56411 12.2048 6.566V6.56095ZM8.77468 6.56601C9.16687 6.56601 9.52084 6.79314 9.67112 7.14112C9.8214 7.48909 9.73835 7.88958 9.46085 8.15594C9.18336 8.42229 8.76611 8.50201 8.40359 8.35776C8.04107 8.21351 7.80443 7.87376 7.80443 7.49732C7.80509 6.98358 8.23946 6.56664 8.77468 6.56601ZM5.35972 6.56601C5.7519 6.56664 6.10521 6.79377 6.25484 7.14175C6.40446 7.49035 6.32075 7.89022 6.04325 8.15657C5.76576 8.42229 5.34851 8.50201 4.98599 8.35776C4.62347 8.21352 4.38749 7.87376 4.38749 7.49732C4.38683 7.24995 4.48966 7.01205 4.67224 6.83743C4.85547 6.66219 5.10332 6.56475 5.36104 6.56601H5.35972Z'],
};

export const faLike = {
  prefix: 'fas',
  iconName: 'like',
  icon: [19, 15, [], null, 'M8.22625 1.25996C6.4403 -0.419263 3.54159 -0.419263 1.75684 1.25996L1.61284 1.39494C-0.407925 3.29479 -0.406362 6.37447 1.61127 8.2716L8.53748 14.7823C8.84584 15.074 9.3514 15.0711 9.65666 14.7823L16.5829 8.2716C18.6021 6.3732 18.6036 3.29468 16.5813 1.39494L16.4373 1.25996C14.6513 -0.419263 11.7554 -0.420712 9.96789 1.25996L9.37933 1.81294C9.22281 1.95953 8.96925 1.95953 8.81426 1.81294L8.22625 1.25996Z'],
};

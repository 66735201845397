<template>
  <div :class="footerThemeClasses">
    <div :class="rowThemeClasses">
      <div>
        <a href="link_stub"
           target="_blank"
           :class="[...buttonThemeClasses, 'support']"
           rel="noopener noreferrer">{{ $t('footer.buttons.support') }}</a>
        <a href="link_stub"
           target="_blank"
           :class="buttonThemeClasses"
           rel="noopener noreferrer">{{ $t('footer.buttons.feedback') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'FooterLayout',
    computed: {
      ...mapState('app', ['isDarkMode']),
      footerThemeClasses() {
        return this.isDarkMode ? ['footer'] : ['footer', 'footer-light'];
      },
      rowThemeClasses() {
        return this.isDarkMode ? ['row'] : ['row', 'row-light'];
      },
      buttonThemeClasses() {
        return this.isDarkMode ? ['button'] : ['button', 'button-light'];
      },
    },
  };
</script>

<style scoped lang="sass">
  @import "~/style/styleguide.sass"

  .footer
    display: flex
    flex-direction: column
    height: base-unit(64)
    background-color: $bg-main
    z-index: $footer-z-index
    position: relative
    &-light
      background-color: $alabaster-color

  .row
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    height: 100%
    padding: 0 base-unit(30)
    +large-devices
      padding: 0 base-unit(50)
    +high-resolution-devices
      width: $max-content-width
      margin: 0 auto
    &-light
      background: $alabaster-color
      color: rgba($bg-main, 0.5)

  .button
    border: 0
    cursor: pointer
    text-decoration: none
    font-size: $base-font-size
    font-weight: 500
    font-style: normal
    font-family: 'Roboto'
    color: rgba(255, 255, 255, 0.5)

    &:hover
      color: rgba(255, 255, 255, 0.8)

    &.support
      margin-right: base-unit(43)

    &-light
      color: rgba($bg-main, 0.5)

      &:hover
        color: rgba($bg-main, 0.8)

</style>

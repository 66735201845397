<template>
  <button v-on="$listeners" :class="buttonThemeClasses">
    <span :class="slotStyle">
      <slot></slot>
    </span>
  </button>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'VFlatButton',
    props: {
      activated: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('app', ['isDarkMode']),
      slotStyle() {
        return this.activated ? 'active' : '';
      },
      buttonThemeClasses() {
        return this.isDarkMode ? ['button'] : ['button', 'button-light'];
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "~/style/styleguide.sass"

  .button
    border: 0
    background: transparent
    color: white
    opacity: 1
    cursor: pointer
    padding: 0

    span
      opacity: 0.5

      &.active
        opacity: 1

    &:hover
      span
        opacity: 0.7

        &.active
          opacity: 1

    &:focus
      outline: 0

    &-light
      color: rgba($bg-main, 0.5)
</style>

<template>
  <div :class="confirmDialogThemeClasses">
    <div class="content">
      <span>{{ text }}</span>
    </div>
    <div class="actions">
      <button @click="$emit('cancel')"
        class="actions-button"
      >
        {{ negativeLabel }}
      </button>
      <button @click="$emit('confirm')"
        class="actions-button"
      >
        {{ positiveLabel }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VConfirmDialog',
    props: {
      text: {
        type: String,
        required: true,
      },
      negativeLabel: {
        type: String,
        required: true,
      },
      positiveLabel: {
        type: String,
        required: true,
      },
      isDarkMode: {
        type: Boolean,
        required: true,
      },
    },
    created() {
      window.addEventListener('click', this.forbidClickOutside, true);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.forbidClickOutside, true);
    },
    methods: {
      forbidClickOutside(e) {
        if (!this.$el.contains(e.target)) {
          e.stopPropagation();
        }
      },
    },
    computed: {
      confirmDialogThemeClasses() {
        return this.isDarkMode ? ['confirm-dialog'] : ['confirm-dialog', 'confirm-dialog-light'];
      },
    },
  };
</script>

<style scoped lang="sass">
  @import "~/style/styleguide.sass"

  .confirm-dialog
    width: base-unit(450)
    height: base-unit(120)
    position: fixed
    left: 0
    right: 0
    top: 0
    margin: 0 auto
    display: flex
    flex-direction: column
    background-color: $card-white
    border-radius: $base-border-radius
    padding: base-unit(18)
    box-sizing: border-box
    z-index: $dialog-z-index
    color: $ebony-clay-color
    +main-font()

    &-light
      box-shadow: 1px 1px 2px $alto-color
      background-color: $alabaster-color
      border: 1px solid $geyser-color

    .content
      display: flex
      flex: 1

      span
        font-size: $base-font-size
        font-weight: 500

    .actions
      display: flex
      justify-content: flex-end
      align-items: center
      margin-top: base-unit(18)

      &-button
        margin-left: base-unit(18)
        height: base-unit(30)
        min-width: base-unit(80)
        box-sizing: border-box
        border: 0
        border-radius: $base-border-radius
        cursor: pointer
        font-size: base-unit(13)
        text-transform: uppercase
        font-weight: bold
        position: relative
        overflow: hidden
        background-color: transparent
        color: $confirm-dark
        border: base-unit(2) solid $confirm-dark

        &:focus
          outline: none

        &:active
          opacity: 0.8

        &:hover
          background-color: $confirm-dark
          color: $card-white
</style>

const mutations = {
  addNotification(state, notification) {
    notification.id = state.lastId + 1;
    state.notifications.push(notification);
  },
  removeNotification(state, notification) {
    state.notifications =
      state.notifications.filter((not) => not.id !== notification.id);
  },
  removeAll(state) {
    state.notifications = [];
    state.lastId = 0;
  },
  toggleSidebar(state, burgerMenuToggled) {
    state.sidebarToggled = burgerMenuToggled;
  },
  setBoardsCardsSorting(state, boardId) {
    if (!state.boardsCardsSorting[boardId]) {
      state.boardsCardsSorting[boardId] = {};
    }
  },
  saveBoardsCardsSortingMethod(state, { boardId, columnSortingMethodName }) {
    state.boardsCardsSorting[boardId].columnSortingMethodName = columnSortingMethodName;
  },
  saveBoardsSortingMethod(state, boardsSortingMethod) {
    state.boardsSortingMethodName = boardsSortingMethod;
  },
  saveActionItemsFilterMethod(state, actionItemsFilterName) {
    state.actionItemsFilterName = actionItemsFilterName;
  },
  saveMentionsSortingMethod(state, mentionsSortingName) {
    state.mentionsSortingName = mentionsSortingName;
  },
  switchTheme(state, { isDarkMode }) {
    localStorage.setItem('isDarkMode', isDarkMode);
    state.isDarkMode = isDarkMode;
  },
  switchProfileDialog(state, profileDialogOpen) {
    state.isProfileDialogOpen = profileDialogOpen;
  },
};

export default mutations;

const mutations = {
  setLoggedIn(state, value) {
    state.loggedIn = value;
  },
  setUser(state, user) {
    state.boardsCount = user.boardsCount;
    state.username = user.username;
    state.id = user.id;
    state.firstname = user.firstname;
    state.lastname = user.lastname;
    state.picture = user.picture;
    state.email = user.email;
    state.workExperience = user.workExperience;
    state.phone = user.phone;
    state.birthday = user.birthday;
    state.allowNews = user.allowNews;
    state.position = user.positionId;
  },
  setBoardsCount(state, value) {
    state.boardsCount += value;
  },
  removeActionItemsNotifications(state) {
    state.mentions = {
      ...state.mentions,
      actionItemsMentions: [],
    };
  },
};

export default mutations;

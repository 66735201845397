var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col main" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.isLoginDialogOpen
        ? _c("login-dialog", {
            on: { save: _vm.closeLoginDialog, close: _vm.closeLoginDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row subheader" }, [
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.$t("signin.textFirstPart")) }
        }),
        _vm._v(" "),
        _c("strong", {
          domProps: { innerHTML: _vm._s(_vm.$t("signin.textBrightPart")) }
        }),
        _vm._v(" "),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.$t("signin.textLastPart")) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("a", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "signin.button",
              expression: "'signin.button'"
            }
          ],
          staticClass: "signin",
          attrs: { rel: "noopener" },
          on: { click: _vm.openLoginDialog }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row logo" }, [
      _c("span", { staticClass: "yellow" }, [_vm._v("My")]),
      _vm._v("Retro\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
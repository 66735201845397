import Router from 'vue-router';


const routes = [
  {
    path: '*',
    component: () => import('~/pages/Dashboard'),
    name: 'dashboard',
    beforeEnter: (to, from, next) => next('/'),
  },
  { path: '/', component: () => import('~/pages/Dashboard'), name: 'dashboard' },
  { path: '/board/:shareLink', component: () => import('~/pages/Board'), name: 'board' },
  { path: '/action_items', component: () => import('~/pages/Mentions'), name: 'actionItemsPage' },
  { path: '/mentions', component: () => import('~/pages/Mentions'), name: 'mentionsPage' },
];

const router = new Router({
  routes,
  mode: 'history',
});

export default router;

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  languages: [
    'en',
    'ru',
  ],
  notifications: [],
  sidebarToggled: { sidebarToggled: false, toggler: '' },
  lastId: 0,
  boardsCardsSorting: {},
  boardsSortingMethodName: '',
  isDarkMode: true,
  isProfileDialogOpen: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

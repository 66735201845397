import axios from 'axios';
import store from './store';

const Nes = require('@hapi/nes/lib/client');

let client = null;

function handler(message) {
  if (!message.type) {
    return;
  }

  const { payload } = message;

  switch (message.type) {
  case 'board':
    store.dispatch('board/updateBoardBySocket', payload);
    break;
  case 'column':
    store.dispatch('board/updateColumnBySocket', payload);
    break;
  case 'card':
    switch (message.action) {
    case 'add':
      store.dispatch('board/addCardBySocket', payload);
      break;
    case 'update':
      store.dispatch('board/updateCardBySocket', payload);
      break;
    case 'batchUpdate':
      store.dispatch('board/updateBatchCardsBySocket', payload);
      break;
    case 'delete':
      store.dispatch('board/deleteCardBySocket', payload);
      break;
    case 'like':
    case 'dislike':
      store.dispatch('board/updateRatingBySocket', payload);
      break;
    case 'move':
      store.dispatch('board/moveCardBySocket', payload);
      break;
    default:
      break;
    }
    break;
  case 'comment':
    switch (message.action) {
    case 'add':
      store.dispatch('board/addCommentBySocket', payload);
      break;
    case 'update':
      store.dispatch('board/updateCommentBySocket', payload);
      break;
    case 'delete':
      store.dispatch('board/deleteCommentBySocket', payload);
      break;
    case 'mention':
      if (payload.isActionItemMention) {
        store.dispatch('mentions/increaseUnreadActionItemsCount', payload);
      } else {
        store.dispatch('mentions/increaseUnreadMentionsCount', payload);
      }
      break;
    default:
      break;
    }
    break;
  default:
    break;
  }
}

export function subscribeClient(linkPrefix, shareLink) {
  if (shareLink === '') {
    client.subscribe(`${linkPrefix}`, handler);
  } else {
    client.subscribe(`/${linkPrefix}/${shareLink}`, handler);
  }
}

export function unsubscribeClient(linkPrefix, shareLink) {
  if (shareLink === '') {
    client.unsubscribe(`${linkPrefix}`, null);
  } else {
    client.unsubscribe(`/${linkPrefix}/${shareLink}`, null);
  }
}

export async function disconnect() {
  if (!client) {
    return;
  }
  unsubscribeClient('/', '');
  await client.disconnect();
}

export async function connect() {
  if (client) {
    await disconnect();
    client = null;
  }

  try {
    const websocketProtocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';

    client = new Nes.Client(`${websocketProtocol}://${window.location.host}/wsapp/`);

    await axios.get('/api/nes/auth');
    await client.connect();
    subscribeClient('/', '');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

import axios from 'axios';
import store from './store';

let retryCount = 0;

function handleRetry(requestConfig, response) {
  const shouldRetry = retryCount < requestConfig.params.retry.count;

  if (!requestConfig.params.retry.count) {
    return Promise.resolve(requestConfig);
  }

  if (shouldRetry) {
    retryCount += 1;

    return new Promise(
      (resolve) => setTimeout(
        () => resolve(axios(requestConfig)), requestConfig.params.retry.delay * retryCount,
      ),
    );
  }

  retryCount = 0;

  return Promise.reject(response.data && response.data.message);
}


axios.interceptors.request.use((config) => config);

axios.interceptors.response.use((response) => response.data, (error) => {
  if (error.response.status === 401) {
    store.dispatch('user/setLoggedIn', false);

    return Promise.reject(new Error('You need to sign in before using service'));
  }
  if (error.config.params && error.config.params.retry) {
    return handleRetry(error.config, error.response);
  }

  return Promise.reject(error.response.data && error.response.data.message);
});

import axios from 'axios';
import sharedActions from '../sharedActions';

const actions = {
  ...sharedActions,
  addCard({ commit, rootState }, columnId) {
    commit('setCreatingCardStatus', true);
    commit('addCard', {
      columnId,
      comments: [],
      content: '',
      id: null,
      isEditing: true,
      likes: [],
      user: { ...rootState.user },
    });
  },
  async updateCard({ commit, dispatch }, card) {
    if (!card.content) {
      if (card.id) {
        return dispatch('deleteCard', card);
      }
      commit('deleteCard', card);

      return Promise.resolve();
    }
    if (card.id) {
      return axios.post(`/api/cards/${card.id}`, {
        content: card.content,
        hide: card.hide,
      });
    }

    return axios.post('/api/cards', {
      columnId: card.columnId,
      content: card.content,
    })
      .catch(() => {
        commit('setCreatingCardStatus', false);
      })
      .finally(() => {
        commit('deleteCard', card);
      });
  },
  deleteCard({ commit }, card) {
    return axios.delete(`/api/cards/${card.id}`);
  },
  getComments({ commit }, cardIdList) {
    return axios.get('/api/comments', {
      params: {
        idList: cardIdList,
      },
    }).then((commentsList) => {
      commit('addCommentsList', commentsList);
    });
  },
  addComment({ commit }, comment) {
    return axios.post('/api/comments', comment);
  },
  updateComment({ commit, dispatch }, comment) {
    if (!comment.content) {
      return dispatch('deleteComment', comment);
    }

    return axios.post(`/api/comments/${comment.id}`, {
      content: comment.content,
    });
  },
  deleteComment({ commit }, comment) {
    return axios.delete(`/api/comments/${comment.id}`);
  },
  updateBoardName({ commit, state }, name) {
    return axios.post(`/api/boards/${state.id}`,
      {
        name,
        description: state.description,
        templateId: state.templateId,
      });
  },
  updateBoardDescription({ commit, state }, description) {
    return axios.post(`/api/boards/${state.id}`,
      {
        name: state.name,
        description,
        templateId: state.templateId,
      });
  },
  changeColumnColor({ commit }, { column, color }) {
    return axios.post(`/api/columns/${column.id}`, { color });
  },
  getBoard({ commit }, shareLink) {
    return axios.get(`/api/boards/${shareLink}`)
      .then((response) => {
        commit('setBoard', response);

        return response;
      });
  },
  getMembers({ state, commit }) {
    return axios.get(`/api/boards/${state.id}/members`)
      .then((response) => {
        commit('setMembers', response);

        return response;
      });
  },
  moveCard({ commit }, card) {
    commit('moveCard', card);

    return axios.post(`/api/cards/${card.id}/position`,
      { columnId: card.columnId });
  },
  exportBoard({ state }) {
    return axios.get(`/api/boards/${state.id}/spreadsheet`);
  },
  shareBoard({ state }, shareLink) {
    return navigator.clipboard.writeText(
      `${window.location.origin}/board/${shareLink}`,
    );
  },
  mentionUser({ state }, { mentionPayload, user }) {
    return axios.post('/api/mention', {
      boardId: state.shareLink,
      mentionPayload,
      user,
    });
  },

  // websocket actions
  clearBoard({ commit }) {
    commit('clearBoard');
  },
  updateBoardBySocket({ commit }, board) {
    commit('updateBoard', board);
  },
  updateColumnBySocket({ commit }, column) {
    commit('updateColumn', column);
  },
  addCardBySocket({ commit }, card) {
    commit('addCard', card);
  },
  updateCardBySocket({ commit }, card) {
    commit('updateCard', card);
  },
  updateBatchCardsBySocket({ commit }, cards) {
    commit('updateBatchCards', cards);
  },
  deleteCardBySocket({ commit }, card) {
    commit('deleteCard', card);
  },
  addCommentBySocket({ commit }, comment) {
    commit('addComment', comment);
  },
  updateCommentBySocket({ commit }, comment) {
    commit('updateComment', comment);
  },
  deleteCommentBySocket({ commit }, comment) {
    commit('deleteComment', comment);
  },
  updateRatingBySocket({ commit }, card) {
    commit('updateRating', card);
  },
  moveCardBySocket({ commit }, card) {
    commit('moveCard', card);
  },
  toggleSpyOnBoard({ commit, state: { id } }, spy) {
    return axios.post(`/api/boards/${id}/spy`, { spy })
      .then(() => {
        commit('toggleSpy');
      });
  },
  toggleSpyOnCard({ commit }, card) {
    return axios.post(`/api/cards/${card.id}/hide`, { hide: !card.hide });
  }
  ,
};

export default actions;

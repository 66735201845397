import Vue from 'vue';
import store from './store';
import i18n from './locales';
import VConfirmDialog from './components/VConfirmDialog';

const NotificationPlugin = {
  install: (vue) => {
    function notify(notification) {
      store.dispatch('app/addNotification', notification);
    }

    function createConfirmDialog(text, positiveLabel, negativeLabel) {
      return new Promise((resolve) => {
        const dialog = new Vue({
          methods: {
            closeHandler(result) {
              return () => {
                resolve(result);
                dialog.$destroy();
                dialog.$el.remove();
              };
            },
          },
          render(h) {
            return h(VConfirmDialog, {
              props: {
                text,
                positiveLabel,
                negativeLabel,
                isDarkMode: store.state.app.isDarkMode,
              },
              on: {
                confirm: this.closeHandler(true),
                cancel: this.closeHandler(false),
              },
            });
          },
        }).$mount();

        document.body.appendChild(dialog.$el);
      });
    }

    // eslint-disable-next-line no-param-reassign
    vue.prototype.$notification = {
      notifyInfo: (content) => {
        if (!content) {
          return;
        }
        notify({ content, type: 'info' });
      },
      notifySuccess: (content) => {
        if (!content) {
          return;
        }
        notify({ content, type: 'success' });
      },
      notifyError: (content = 'Undefined error') => {
        notify({ content, type: 'error' });
      },
      confirm(
        text,
        positiveLabel = i18n.t('confirmDialog.yes'),
        negativeLabel = i18n.t('confirmDialog.no'),
      ) {
        return createConfirmDialog(text, positiveLabel, negativeLabel);
      },
    };
  },
};

export default NotificationPlugin;
